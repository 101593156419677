<template>
  <p class="card-text text-center mt-2">
    <router-link :to="'/auth/login'">
      Back to login
    </router-link>
  </p>
</template>

<script>
export default {
  name: "BackToLogin"
}
</script>

<template>
  <div class="auth-form-wrapper">
    <div class="flex justify-content-center auth-wrapper">
      <div class="card">

        <h5 class="text-center">Forgot Password</h5>

        <p> Enter your email and we'll send you instructions to reset your password </p>

        <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
          <!-- Email -->
          <div class="field">
            <div class="p-float-label p-input-icon-right">
              <i class="pi pi-envelope"/>
              <InputText id="email" v-model="v$.email.$model" :class="{'p-invalid':v$.email.$invalid && submitted}" aria-describedby="email-error"/>
              <label for="email" :class="{'p-error':v$.email.$invalid && submitted}">Email*</label>
            </div>

            <span v-if="v$.email.$error && submitted">
              <span id="email-error" v-for="(error, index) of v$.email.$errors" :key="index">
                <small class="p-error">{{ error.$message.replace('Value', 'Email') }}</small>
              </span>
            </span>
            <small
              v-else-if="(v$.email.$invalid && submitted) || v$.email.$pending.$response"
              class="p-error">{{ v$.email.required.$message.replace('Value', 'Email') }}
            </small>
          </div>

          <Button type="submit" label="Submit" class="mt-2"/>

          <back-to-login/>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { email, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import AuthResource from "@/http/AuthResource";
import toastParamBuilder from "@/builders/ToastParamBuilder";
import BackToLogin from "@components/auth/BackToLogin";

export default {
  setup: () => ({v$: useVuelidate()}),

  components: {
    BackToLogin,
  },

  data() {
    return {
      email: '',
      submitted: false,
    }
  },

  validations() {
    return {
      email: { required, email },
    }
  },

  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true;

      if (!isFormValid) {
        return;
      }

      AuthResource.forgotPassword({
        email: this.email,
      }).then(response => {
        this.$toast.add(toastParamBuilder.success(response.status));
        this.resetForm();
        this.$router.push({ name: 'login' });
      }).catch(error => {
        this.$toast.add(toastParamBuilder.error(error.details.email[0]));
      });
    },

    resetForm() {
      this.email = '';
      this.submitted = false;
    },
  }
}
</script>
